import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './checkbox.scss';

let CHECKBOX_COUNT = 0;

class CheckBox extends Component {

	constructor(props) {
		super(props);

		this.state = {
			// Only used when onChange is not defined
			checked: false,
		};

		CHECKBOX_COUNT ++;
		this.id = CHECKBOX_COUNT;
	}

	render() {
		const checked = this.props.onChange ? this.props.checked : this.state.checked;
		const classes = classNames({
			'checkbox': true,
			'checkbox--replaced': true,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<label className={classes}>
				<input type="checkbox"
					id={'checkbox_' + this.id}
					checked={checked}
					className="checkbox__input"
					name={this.props.name}
					value={this.props.value}
					onChange={this.handleChange.bind(this)}/>

				<span className="checkbox__icon"/>

				{this.props.children}
			</label>
		);
	}

	handleChange(event) {
		const checked = event.currentTarget.checked;
		const value = event.currentTarget.value;

		if (this.props.onChange) {
			this.props.onChange(checked, value);
		} else {
			this.setState({checked: checked});
		}
	}
}

CheckBox.propTypes = {
	name: PropTypes.string.isRequired,
	checked: PropTypes.bool,
	value: PropTypes.string,
	onChange: PropTypes.func,
	children: PropTypes.node,
	label: PropTypes.string,
	className: PropTypes.string,
};

CheckBox.defaultProps = {
	value: 'y',
	checked: false,
	label: ''
};

export default CheckBox;