const filterAndSortDates = dates => {
	if (! dates || dates.length === 0) {
		return [];
	}

	if (dates.length === 1) {
		return dates;
	}

	return dates.filter(date => ! ! date).sort((a, b) => a.getTime() > b.getTime() ? 1 : - 1);
}

/**
 * @param array|Date[] dates
 * @return {Date|null}
 */
export function getEarliestDate(dates) {
	const sortedDates = filterAndSortDates(dates);

	return sortedDates ? sortedDates[0] : null;
}

/**
 * @param array|Date[] dates
 * @return {Date|null}
 */
export function getLatestDate(dates) {
	const sortedDates = filterAndSortDates(dates);

	return sortedDates ? sortedDates[sortedDates.length - 1] : null;
}

/**
 * @param Date date
 * @return {boolean}
 */
export function isWeekend(date) {
	const dayInWeek = date.getDay();

	return dayInWeek === 6 || dayInWeek === 0;
}

export default {
	filterAndSortDates,
	getEarliestDate,
	getLatestDate,
	isWeekend,
};