import React from 'react';
import PropTypes from 'prop-types';

import {IntlProvider} from 'react-intl';

import dutch from '../data/locales/nl-NL.json';
import english from '../data/locales/en-US.json';

const LOCALIZED_MESSAGES = {
	en: english,
	nl: dutch,
};

const DEFAULT_LOCALE = 'nl';

class LocalizedApp extends React.Component {

	constructor(props) {
		super(props);

		this.handleLocaleChanged = this.handleLocaleChanged.bind(this);

		const startLocale = this.getDefaultLocale();

		this.state = {
			locale: startLocale,
			messages: LOCALIZED_MESSAGES[startLocale],
		};
	}

	render() {
		return (
			<IntlProvider locale={this.state.locale} messages={this.state.messages}>
				{this.props.children(this.state.locale)}
			</IntlProvider>
		);
	}

	handleLocaleChanged(newLocale) {
		this.setState({
			locale: newLocale,
			messages: LOCALIZED_MESSAGES[newLocale],
		});
	}

	getDefaultLocale() {
		if (this.props.locale) {
			return this.props.locale;
		}

		return DEFAULT_LOCALE;
	}
}

LocalizedApp.propTypes = {
	children: PropTypes.func.isRequired,
	locale: PropTypes.string,
};

export default LocalizedApp;
