import React, { Component } from 'react';
import classNames from 'classnames';
import { isWeekend } from 'DateHelper.js';
import PropTypes from 'prop-types';

const DAY = 24 * 60 * 60 * 1000;

class WeeksAndDays extends Component {

	static propTypes = {
		start: PropTypes.instanceOf(Date).isRequired,
		end: PropTypes.instanceOf(Date).isRequired,
		current: PropTypes.instanceOf(Date),
		earliest: PropTypes.instanceOf(Date),
		latest: PropTypes.instanceOf(Date),
		availableStart: PropTypes.instanceOf(Date),
		availableEnd: PropTypes.instanceOf(Date),
		disableWeekends: PropTypes.bool,
	}

	render() {
		const weeks = [];
		const first = this.props.start;
		const last = this.props.end;

		while (first < last) {
			weeks.push(this.renderDaysInWeek(first));
			first.setDate(first.getDate() + 7); // Next week
		}

		return (<tbody>{weeks}</tbody>);
	}

	renderDaysInWeek(weekStart) {
		const dates = [];
		const referenceDate = new Date(weekStart);

		for (let i = 0; i < 7; i ++) {
			dates.push(
				<td key={i} className="day-picker__cell">
					<a href="#"
						data-date={new Date(referenceDate)}
						className={this.getDayClasses(referenceDate)}>
						{referenceDate.getDate()}
					</a>
				</td>
			);

			// Next date
			referenceDate.setDate(referenceDate.getDate() + 1);
		}

		return (<tr key={weekStart.getTime()}>{dates}</tr>);
	}

	isAvailableDate(date) {
		if (this.props.disableWeekends && isWeekend(date)) {
			return false;
		}

		if (this.props.earliest && this.props.earliest - DAY > date) {
			return false;
		}

		if (this.props.latest && this.props.latest < date) {
			return false;
		}

		if (this.props.availableStart && date < this.props.availableStart) {
			return false;
		}

		if (this.props.availableEnd && date > this.props.availableEnd) {
			return false;
		}

		return true;
	}

	isCurrentDate(date) {
		if (! this.props.current) {
			return false;
		}

		return date.toDateString() === this.props.current.toDateString();
	}

	getDayClasses(date) {
		const isDisabled = ! this.isAvailableDate(date);
		return classNames({
			'day-picker__day': true,
			'day-picker__day--not-available': isDisabled,
			'day-picker__day--current': this.isCurrentDate(date)
		});
	}
}

export default WeeksAndDays;