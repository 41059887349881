import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MediaQueryListener from './MediaQueryListener.js';

const mediaQueryListener = new MediaQueryListener({
	mobile: 'screen and (max-width: 767px)',
	tablet: 'screen and (min-width: 768px) and (max-width: 1024px)',
	desktop: 'screen and (min-width: 1025px)'
});

class BreakPoint extends Component {

	static propTypes = {
		children: PropTypes.node.isRequired,
		device: function(props, propName) {
			if (! /^((mobile|tablet|desktop)(?: |$))+$/.test(props[propName])) {
				return new Error('Invalid device');
			}
		},
		className: PropTypes.string
	};

	constructor(props) {
		super(props);

		mediaQueryListener.addListeners(this.getRelevantBreakPoints(), this);
	}

	shouldComponentUpdate() {
		return mediaQueryListener.shouldRender(this);
	}

	componentWillUnmount() {
		mediaQueryListener.removeListeners(this);
	}

	render() {
		if (! mediaQueryListener.shouldRender(this)) {
			return null;
		}

		const classes = classNames({
			'breakpoint-wrapper': true,
			[this.props.className]: ! ! this.props.className
		});

		return (
			<span className={classes}>
				{this.props.children}
			</span>
		);
	}

	getRelevantBreakPoints() {
		return this.props.device.trim().split(' ');
	}
}

export default BreakPoint;
