import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ButtonList from '../ButtonList/ButtonList';
import SVGIcon from '../SVGIcon/SVGIcon';

import './button.scss';

class Button extends Component {

	render() {
		if (this.props.align !== 'left') {
			return (
				<ButtonList align={this.props.align}>
					{this.renderButton()}
				</ButtonList>
			);
		}

		return this.renderButton();
	}

	renderButton() {
		const classes = classNames({
			'button': true,
			'button--has-icon': ! ! this.props.iconName && this.props.iconPosition !== 'left',
			'button--has-icon-left': ! ! this.props.iconName && this.props.iconPosition === 'left',
			'button--disabled': this.props.disabled,
			['button--size-' + this.props.size]: ! ! this.props.size,
			[this.props.className]: ! ! this.props.className
		});

		switch (this.props.type) {
			case 'anchor':
				return (
					<a className={classes}
						key="a"
						onClick={! this.props.disabled ? this.props.onClick : this.handleDisabledClick.bind(this)}
						href={this.props.url}
						target={this.props.target}>

						{this.renderButtonContents()}
					</a>
				);

			case 'button':
			case 'submit':
				return (
					<button
						key="button"
						disabled={this.props.disabled}
						className={classes}
						type={this.props.type}
						onClick={! this.props.disabled ? this.props.onClick : this.handleDisabledClick.bind(this)}>

						{this.renderButtonContents()}
					</button>
				);

			case 'span':
				return (
					<span className={classes} key="span">
						{this.renderButtonContents()}
					</span>
				);
		}

		return null;
	}

	renderButtonContents() {
		return [
			this.renderIcon('left'),
			<span className="button__label" key="label">{this.props.children}</span>,
			this.renderIcon('right'),
		];
	}

	renderIcon(position) {
		if (! this.props.iconName) {
			return null;
		}

		if (this.props.iconPosition !== position) {
			return null;
		}

		const classes = classNames({
			'button__icon': true,
			[this.props.iconClassName]: ! ! this.props.iconClassName,
		});

		return (
			<SVGIcon name={this.props.iconName} className={classes} key="icon"/>
		);
	}

	handleDisabledClick(event) {
		event.preventDefault();
		event.stopPropagation();
	}
}

Button.propTypes = {
	children: PropTypes.node.isRequired,
	type: PropTypes.oneOf(['anchor', 'button', 'submit', 'span']),
	url: PropTypes.string,
	buttonType: PropTypes.string,
	disabled: PropTypes.bool,
	target: PropTypes.string,
	className: PropTypes.string,
	iconName: PropTypes.string,
	iconClassName: PropTypes.string,
	iconPosition: PropTypes.oneOf(['left', 'right']),
	align: PropTypes.oneOf(['left', 'center', 'right']),
	size: PropTypes.oneOf(['default', 'large']),
	onClick: PropTypes.func,
};

Button.defaultProps = {
	type: 'anchor',
	buttonType: 'submit',
	url: '#',
	disabled: false,
	align: 'left',
	target: '_self',
	iconPosition: 'right',
};

export default Button;