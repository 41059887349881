import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MonthPicker from './components/MonthPicker/MonthPicker';
import DayPicker from './components/DayPicker/DayPicker';
import './calendar-picker.scss';

class CalendarDatePicker extends Component {

	render() {
		return (
			<div className="calendar-date-picker">
				{this.renderPickers()}
			</div>
		);
	}

	renderPickers() {
		const components = [];
		const style = {width: (100 / this.props.months).toString() + '%'};
		let selectedMonth = null;

		for (let i = 0; i < this.props.months; i ++) {
			selectedMonth = this.getMonthWithOffset(this.props.month, i);

			// Return early when we exceed the latest
			if (selectedMonth > this.props.latest) {
				return components;
			}

			components.push(
				<div key={i}
					style={style}
					className="calendar-date-picker__month">
					<MonthPicker
						isFirstPicker={i === 0 && this.props.months > 1}
						isLastPicker={i === this.props.months - 1 && this.props.months > 1}
						key={'month_' + i}
						earliest={this.props.earliest}
						latest={this.props.latest}
						value={selectedMonth}
						onChange={month => this.handleMonthChange(month, i)}/>
					<DayPicker
						key={'day_' + i}
						earliest={this.props.earliest}
						latest={this.props.latest}
						disableWeekends={this.props.disableWeekends}
						month={selectedMonth}
						value={this.getValue()}
						onChange={this.props.onChange}/>
				</div>
			);
		}

		return components;
	}

	/**
	 * @param {Date} reference
	 * @param {Number} offset
	 */
	getMonthWithOffset(reference, offset) {
		if (! reference) {
			console.error('Invalid reference date');
			return null;
		}

		const copy = new Date(reference.getTime());
		copy.setDate(1);
		copy.setMonth(copy.getMonth() + offset);
		copy.setHours(0);
		copy.setMinutes(0);
		copy.setSeconds(0);
		copy.setMilliseconds(0);

		return copy;
	}

	getValue() {
		return this.props.value ? this.props.value : new Date();
	}

	handleMonthChange(newValue, index) {
		if (this.props.changeMonth) {
			const correctedMonth = new Date(newValue.getTime());
			correctedMonth.setMonth(correctedMonth.getMonth() - index);

			this.props.changeMonth(correctedMonth);
		}
	}
}

CalendarDatePicker.propTypes = {
	earliest: PropTypes.instanceOf(Date),
	latest: PropTypes.instanceOf(Date),
	value: PropTypes.instanceOf(Date),
	month: PropTypes.instanceOf(Date).isRequired,
	months: PropTypes.number.isRequired,
	changeMonth: PropTypes.func,
	disableWeekends: PropTypes.bool,
	onChange: PropTypes.func
};

CalendarDatePicker.defaultProps = {
	months: 1,
};

export default CalendarDatePicker;
