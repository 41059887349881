import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import Intl from '../../../../../../../../../shared/shapes/Intl';
import Stepper from 'components/Stepper/Stepper';

const YEAR = 365 * 24 * 60 * 60 * 1000;

class MonthPicker extends Component {

	static propTypes = {
		earliest: PropTypes.instanceOf(Date),
		latest: PropTypes.instanceOf(Date),
		value: PropTypes.instanceOf(Date),
		onChange: PropTypes.func,
		isFirstPicker: PropTypes.bool,
		isLastPicker: PropTypes.bool,
		intl: Intl.isRequired,
	};

	static defaultProps = {
		isFirstPicker: true,
		isLastPicker: true,
		earliest: new Date(),
		latest: new Date((new Date()).getTime() + YEAR),
	};

	render() {
		return (
			<Stepper
				className="month-picker"
				mode="horizontal"
				// hidePreviousArrow={! this.props.isFirstPicker}
				// hideNextArrow={! this.props.isLastPicker}
				onChange={this.handleChange.bind(this)}
				options={this.getOptions(
					this.getEarliestMomentInMonth(this.props.earliest),
					this.getLastMomentInMonth(this.props.latest)
				)}
				value={this.props.value ? this.props.value.getTime() : null}/>
		);
	}

	handleChange(timestamp) {
		if (this.props.onChange) {
			this.props.onChange(new Date(timestamp));
		}
	}

	getOptions(earliest, latest) {
		const options = [];
		while (earliest < latest) {
			options.push({
				label: this.props.intl.formatDate(earliest, {month: 'long'}),
				value: earliest.getTime()
			});

			earliest.setMonth(earliest.getMonth() + 1);
		}

		return options;
	}

	getEarliestMomentInMonth(date) {
		const firstSecond = new Date(date.getTime());
		firstSecond.setDate(1);
		firstSecond.setHours(0);
		firstSecond.setMinutes(0);
		firstSecond.setSeconds(0);
		firstSecond.setMilliseconds(0);

		return firstSecond;
	}

	getLastMomentInMonth(date) {
		const lastSecond = this.getEarliestMomentInMonth(date);
		lastSecond.setMonth(lastSecond.getMonth() + 1);
		lastSecond.setSeconds(- 1);

		return lastSecond;
	}
}

export default injectIntl(MonthPicker);
