
const addMatchMediaListeners = function(mediaQueries, callback) {

	// Create the listeners
	for (let key in mediaQueries) {

		let mediaQueryList = window.matchMedia(mediaQueries[key]);

		// Add listener for when the status of the MQL changes
		mediaQueryList.addListener(() => {
			callback(key, mediaQueryList.matches);
		});

		// And call immediately
		callback(key, mediaQueryList.matches);
	}
};

class MediaQueryListener {

	constructor(mediaQueries) {
		this.listeners = {};
		this.devices = Object.keys(mediaQueries);
		this.matches = {};

		addMatchMediaListeners(mediaQueries, this.handleMediaQueryMatchUpdate.bind(this));
	}

	addListeners(devices, component) {
		let addComponentToDeviceListener = (device) => {
			if (!this.listeners[device]) {
				this.listeners[device] = new Set();
			}

			this.listeners[device].add(component);
		};
		devices.forEach(addComponentToDeviceListener);
	}

	removeListeners(component) {
		this.devices.map(device => {
			this.listeners[device].delete(component);
		});
	}

	shouldRender(component) {
		for (let i = 0; i < this.devices.length; i++) {
			if (this.shouldRenderComponentOnDevice(component, this.devices[i])) {
				return true;
			}
		}
		return false;
	}

	handleMediaQueryMatchUpdate(device, match) {
		if (this.matches[device] === match) {
			return;
		}

		this.matches[device] = match;

		if (this.listeners[device]) {
			this.listeners[device].forEach(component => component.forceUpdate());
		}
	}

	shouldRenderComponentOnDevice(component, device) {
		if (!this.listeners[device]) {
			return false;
		}

		return this.listeners[device].has(component) && this.matches[device];
	}

}

export default MediaQueryListener;