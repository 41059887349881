import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TimePickerSelect from './components/TimePickerSelect/TimePickerSelect';

import './time-picker.scss';

class TimePicker extends Component {

	render() {
		return (
			<div className="time-picker" ref="root">
				<TimePickerSelect
					name={this.props.name}
					earliest={this.props.earliest}
					latest={this.props.latest}
					interval={this.props.interval}
					value={this.props.value}
					onChange={this.props.onChange}/>
			</div>
		);
	}
}

TimePicker.propTypes = {
	name: PropTypes.string.isRequired,
	fullWidth: PropTypes.bool,
	earliest: PropTypes.instanceOf(Date).isRequired,
	latest: PropTypes.instanceOf(Date).isRequired,

	/**
	 * Interval between options in minutes
	 */
	interval: PropTypes.number.isRequired,
	value: PropTypes.instanceOf(Date),
	onChange: PropTypes.func,
};

export default TimePicker;