import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import Icon from 'components/SVGIcon/SVGIcon.jsx';

import './slide-out-select.scss';

class SlideOutSelect extends Component {

	static propTypes = {
		className: PropTypes.string,
		triggerClassName: PropTypes.string,
		contentClassName: PropTypes.string,
		children: PropTypes.node.isRequired,
		label: PropTypes.node.isRequired,
		align: PropTypes.oneOf(['left', 'center', 'right']),
		isOpen: PropTypes.bool.isRequired,
		fullHeight: PropTypes.bool,
		open: PropTypes.func.isRequired,
		close: PropTypes.func.isRequired,
		minContentWidth: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
	};

	static defaultProps = {
		isOpen: false,
		align: 'left',
	};

	componentDidUpdate() {
		const node = ReactDOM.findDOMNode(this.contentWrapper);

		node.style.height = this.getContentHeight() + 'px';
	}

	render() {
		const classes = classNames({
			'slide-out-select': true,
			'slide-out-select--full-height': this.props.fullHeight,
			'slide-out-select--open': this.props.isOpen,
			['slide-out-select--align-' + this.props.align]: true,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<div className={classes}>

				{this.renderTrigger()}

				<TransitionGroup
					className="slide-out-select__content-wrapper"
					ref={el => this.contentWrapper = el}
					style={this.getInlineContentStyles()}
					component="div">

					{this.getContent()}

				</TransitionGroup>
			</div>
		);
	}

	renderTrigger() {
		const triggerClasses = classNames({
			'slide-out-select__trigger': true,
			'slide-out-select__trigger--open': this.props.isOpen,
			[this.props.triggerClassName]: ! ! this.props.triggerClassName,
		});

		return (
			<a href="#" onClick={this.handleTriggerClick.bind(this)} className={triggerClasses}>
				<div className="slide-out-select__trigger__label-wrapper">
					<div className="slide-out-select__trigger__label">
						{this.props.label}
					</div>
				</div>
				<Icon name="arrow-down" className="slide-out-select__icon"/>
			</a>
		);
	}

	handleTriggerClick(event) {
		event.preventDefault();
		event.stopPropagation();

		if (this.props.isOpen) {
			this.props.close();
		} else {
			this.props.open();
		}
	}

	getContentHeight() {
		if (! this.props.isOpen) {
			return 0;
		}

		return ReactDOM.findDOMNode(this.content).clientHeight + 5;
	}

	getContent() {
		if (! this.props.isOpen) {
			return null;
		}

		const classes = classNames({
			'slide-out-select__content-wrapper__content': true,
			[this.props.contentClassName]: ! ! this.props.contentClassName,
		});

		return (
			<CSSTransition
				classNames="animate-drop-down-medium"
				timeout={{enter: 300, exit: 300}}>
				<div className={classes} ref={content => this.content = content}>
					{this.props.children}
				</div>
			</CSSTransition>
		);
	}

	getInlineContentStyles() {
		const styles = {};
		if (this.props.minContentWidth) {
			const w = this.props.minContentWidth;
			styles.minWidth = typeof w === 'number' ? w + 'px' : w;
		}

		return styles;
	}
}

export default SlideOutSelect;
