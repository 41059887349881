import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

import FieldWrapper from '../../../../shared/components/FieldWrapper/FieldWrapper';
import Input from '../../../../shared/components/Input/Input';

import './discount-code-input.scss';

class DiscountCodeInput extends React.PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			toggled: ! ! props.value,
			togglable: ! props.value,
		};
	}

	render() {
		const classes = classNames({
			'discount-code-input': true,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<div className={classes}>
				{this.renderToggleIfCanBeToggled()}
				{this.renderInputIfToggled()}
			</div>
		);
	}

	renderToggleIfCanBeToggled() {
		if (! this.state.togglable) {
			return null;
		}

		return (
			<div className="discount-code-input__toggle-wrapper">
				<a href="#" className="discount-code-input__trigger"
				   onClick={this.handleTriggerClick}>
					<FormattedMessage id="DiscountCode.Toggle"/>
				</a>
			</div>
		);
	}

	renderInputIfToggled() {
		if (! this.state.toggled) {
			return null;
		}

		return (
			<FieldWrapper label="Kortingscode">
				<Input
					name="coupon"
					type="text"
					value={this.props.value}
					onChange={this.handleInputChange}/>
				<div className="discount-code__note">
					<FormattedMessage id="DiscountCode.Note"/>
				</div>
			</FieldWrapper>
		);
	}

	handleTriggerClick = (event) => {
		event.preventDefault();

		const nowToggled = ! this.state.toggled;
		this.setState({toggled: nowToggled});

		if (! nowToggled && this.props.onChange) {
			this.props.onChange('');
		}
	};

	handleInputChange = (event) => {
		if (this.props.onChange) {
			this.props.onChange(event.target.value);
		}
	};
}

DiscountCodeInput.propTypes = {
	className: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
};

export default DiscountCodeInput;
