import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {injectIntl} from 'react-intl';

import { getEarliestDate } from 'DateHelper.js';
import isDescendantOf from 'isDescendantOf.js';

import BreakPoint from 'components/BreakPoint/BreakPoint';
import SlideOutSelect from 'components/SlideOutSelect/SlideOutSelect';
import Intl from '../../../../../../shared/shapes/Intl';

import SelectDatePicker from './SelectDatePicker/SelectDatePicker';
import CalendarDatePicker from './CalendarDatePicker/CalendarPicker';

const DAY = 24 * 60 * 60 * 1000;
const YEAR = 365 * DAY;

const today = new Date();
const nextYear = new Date(today.getTime() + YEAR);

import './date-picker.scss';

class DatePicker extends Component {

	static propTypes = {
		name: PropTypes.string.isRequired,
		months: PropTypes.number.isRequired,
		initialValue: PropTypes.instanceOf(Date),
		earliest: PropTypes.instanceOf(Date),
		latest: PropTypes.instanceOf(Date),
		disableWeekends: PropTypes.bool,
		minCalendarWidth: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
		onChange: PropTypes.func,
		className: PropTypes.string,
		intl: Intl.isRequired,
	};

	static defaultProps = {
		earliest: today,
		latest: nextYear,
		months: 1,
	};

	constructor(props) {
		super(props);

		this.boundBodyClick = this.handleBodyClick.bind(this);

		this.state = {
			isOpen: false,
			value: props.initialValue,
			month: props.initialValue ? props.initialValue : today
		};
	}

	componentDidMount() {
		document.body.addEventListener('click', this.boundBodyClick);
	}

	UNSAFE_componentWillUpdate(nextProps) {
		if (! nextProps.initialValue) {
			return;
		}

		if (! this.props.initialValue) {
			return this.setState({value: nextProps.initialValue});
		}

		if (nextProps.initialValue.getTime() != this.props.initialValue.getTime()) {
			return this.setState({value: nextProps.initialValue});
		}
	}

	componentWillUnmount() {
		document.body.removeEventListener('click', this.boundBodyClick);
		this.boundBodyClick = null;
	}

	render() {
		let date = this.state.value;
		if (! this.isValidDate(date)) {
			date = null;
		}

		return (
			<div className={classNames('date-picker', this.props.className)}>
				<BreakPoint device="mobile">
					<SelectDatePicker key="select"
						name={this.props.name}
						earliest={this.props.earliest}
						latest={this.props.latest}
						value={this.state.value}
						disableWeekends={this.props.disableWeekends}
						onChange={this.onChange.bind(this)}/>
				</BreakPoint>

				<BreakPoint device="tablet desktop">
					<input type="hidden" value={date ? date.toISOString() : ''} name={this.props.name}/>

					<SlideOutSelect key="slide-out-select"
						label={this.getPickerLabel()}
						open={this.open.bind(this)}
						close={this.close.bind(this)}
						minContentWidth={this.props.minCalendarWidth}
						isOpen={this.state.isOpen}>

						<div ref={el => this.picker = el}>
							<CalendarDatePicker
								earliest={this.props.earliest}
								latest={this.props.latest}
								month={getEarliestDate([this.state.month, this.props.latest])}
								value={this.state.value}
								months={this.props.months}
								disableWeekends={this.props.disableWeekends}
								changeMonth={this.changeMonth.bind(this)}
								onChange={this.onChange.bind(this)}/>
						</div>
					</SlideOutSelect>
				</BreakPoint>
			</div>
		);
	}

	onChange(date) {
		this.setState({value: date, isOpen: false});

		if (this.props.onChange) {
			this.props.onChange(date);
		}
	}

	handleBodyClick(event) {
		if (! this.state.isOpen) {
			return;
		}

		if (isDescendantOf(event.target, this.picker)) {
			return;
		}

		this.close();
	}

	open() {
		// Reset to month to show current date
		//const month = this.state.value ? this.state.value : this.state.month;
		this.setState({isOpen: true});
	}

	close() {
		this.setState({isOpen: false});
	}

	changeMonth(firstOfMonth) {
		this.setState({month: firstOfMonth})
	}

	isValidDate(date) {
		if (Object.prototype.toString.call(date) === '[object Date]') {
			return ! isNaN(date.getTime());
		}

		return false;
	}

	getPickerLabel() {
		if (this.state.value) {
			return this.props.intl.formatDate(this.state.value, {day: 'numeric', month: 'long', year: 'numeric'});
		}

		return this.props.intl.formatMessage({id: 'TripDetails.Date.Default'});
	}
}

export default injectIntl(DatePicker);
