import React, {Component} from 'react';
import request from 'superagent';
import PropTypes from 'prop-types';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {FormattedMessage, injectIntl} from 'react-intl';

import ArrangementType from 'shapes/ArrangementType.js';
import Location from 'shapes/Location.js';
import Intl from 'shapes/Intl.js';
import {USER_DEFINED_LOCATION_ID} from 'Constants.js';
import formatDateAsYYYYMMDDHHMM from '../../util/formatDateAsYYYYMMDDHHMM';

import Button from 'components/Button/Button';
import SVGIcon from 'components/SVGIcon/SVGIcon';

import TripDetails from './screens/TripDetails/TripDetails';
import ContactDetails from './screens/ContactDetails/ContactDetails';
import GrandTotalLoader from './components/GrandTotal/GrandTotalLoader';
import DiscountCodeInput from './components/DiscountCodeInput/DiscountCodeInput';

import './booking-form.scss';

const initialData = {
	arrangementType: '',
	date: undefined,
	time: undefined,
	duration: undefined,

	numPassengers: undefined,
	adults: undefined,
	children: undefined,
	toddlers: undefined,

	startLocation: '',
	endLocation: '',
	userDefinedStartLocation: '',
	userDefinedEndLocation: '',

	firstName: '',
	lastName: '',
	phoneNumber: '',
	email: '',
	comments: '',
	discountCode: '',
};

class BookingForm extends Component {
	constructor(props) {
		super(props);

		this.handleDataChanged = this.handleDataChanged.bind(this);
		this.handleChildFieldsToggled = this.handleChildFieldsToggled.bind(this);
		this.handleNoneDefaultLocationFieldsToggled = this.handleNoneDefaultLocationFieldsToggled.bind(this);

		// Merge in initial data
		if (props.initialArrangementType) {
			const arrangementType = this.getArrangementById(props.initialArrangementType);
			if (arrangementType) {
				initialData.arrangementType = arrangementType.id;

				if (arrangementType.minimumDuration) {
					initialData.duration = arrangementType.minimumDuration;
				}
			}
		}

		const defaultLocation = this.props.locations.find(location => location.isDefault) || this.props.locations[0];

		this.state = {
			isSubmitting: false,
			isRequestingPrice: false,
			errorMessage: '',
			errorFields: {},
			submittedSuccessfully: false,
			showChildrenFields: false,
			showNoneDefaultLocationFields: false,

			step: 1,

			// Data values
			data: Object.assign({}, initialData, {
				discountCode: this.props.discountCode,
				startLocation: defaultLocation.name,
				endLocation: defaultLocation.name,
			}),
		};
	}

	UNSAFE_componentWillUpdate(nextProps, nextState) {
		if (this.willChangeArrangementTypeToSet(this.state.data, nextState.data)) {
			this.setState({
				data: {
					...nextState.data,
					duration: this.getArrangementById(nextState.data.arrangementType).minimumDuration
				}
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.submittedSuccessfully && !prevState.submittedSuccessfully) {
			setTimeout(() => {
				this.setState({submittedSuccessfully: false});
			}, 5000);

			this.trackProgress('complete');
		}

		if (prevState.step !== this.state.step) {
			this.el.scrollIntoView({behavior: 'smooth', block: 'start'});

			if (!this.state.submittedSuccessfully) {
				this.trackProgress('step' + this.state.step);
			}
		}
	}

	render() {
		return (
			<form className="booking-form" action="#" method="post" onSubmit={this.handleSubmit.bind(this)}
				  ref={el => this.el = el} acceptCharset="utf-8">
				<TransitionGroup>
					{this.renderStep1()}
					{this.renderStep2()}
				</TransitionGroup>

				{/*{this.renderDiscountCodeInputWhenAvailable()}*/}
				{this.renderPricingWhenAvailable()}
				{this.renderErrorResponseMessageWhenAvailable()}
				{this.renderSubmitButton()}
				{this.renderSuccessScreen()}
				{this.renderLoadingWhenSubmitting()}
			</form>
		);
	}

	renderStep1() {
		if (this.state.step !== 1) {
			return null;
		}

		const data = this.state.data;

		return (
			<CSSTransition
				key="step1"
				classNames="transition-slide-right"
				timeout={{enter: 1000, exit: 500}}>
				<TripDetails
					className="booking-form__section"

					arrangementType={data.arrangementType}
					date={data.date}
					time={data.time}

					duration={data.duration}
					numPassengers={data.numPassengers}
					adults={data.adults}
					children={data.children}
					showChildrenFields={this.state.showChildrenFields}

					toddlers={data.toddlers}
					startLocation={data.startLocation}
					endLocation={data.endLocation}
					showNoneDefaultLocationFields={this.state.showNoneDefaultLocationFields}
					userDefinedStartLocation={data.userDefinedStartLocation}
					userDefinedEndLocation={data.userDefinedEndLocation}

					fieldsWithError={this.state.errorFields}

					locations={this.props.locations}
					onUpdate={this.handleDataChanged}
					onChildFieldsToggled={this.handleChildFieldsToggled}
					onNoneDefaultLocationFieldsToggled={this.handleNoneDefaultLocationFieldsToggled}
					arrangementTypes={this.props.arrangementTypes}/>
			</CSSTransition>
		);
	}

	renderStep2() {
		if (this.state.step !== 2) {
			return null;
		}

		return (
			<CSSTransition
				key="step2"
				classNames="transition-slide-left"
				timeout={{enter: 1000, exit: 500}}>
				<ContactDetails
					className="booking-form__section"
					firstName={this.state.data.firstName}
					lastName={this.state.data.lastName}
					phoneNumber={this.state.data.phoneNumber}
					email={this.state.data.email}
					comments={this.state.data.comments}
					acceptedTerms={this.state.data.acceptedTerms}

					fieldsWithError={this.state.errorFields}

					onUpdate={this.handleDataChanged}
					onBackRequested={this.handleBackRequested.bind(this)}/>
			</CSSTransition>
		);
	}

	renderLoadingWhenSubmitting() {
		if (!this.state.isSubmitting) {
			return null;
		}

		return (<span><FormattedMessage id="Submitting"/></span>);
	}

	renderErrorResponseMessageWhenAvailable() {
		if (!this.state.errorMessage) {
			return null;
		}

		return (
			<div className="booking-form__error-message">{this.state.errorMessage}</div>
		);
	}

	renderSuccessScreen() {
		if (!this.state.submittedSuccessfully) {
			return null;
		}

		return (
			<div className="booking-form__success">
				<SVGIcon name="checkmark" className="booking-form__success__icon"/>
				<div className="booking-form__success__message">
					<FormattedMessage id="Submitted"/>
				</div>
			</div>
		);
	}

	renderPricingWhenAvailable() {
		if (!this.hasCompletedStep1()) {
			return null;
		}

		return (
			<GrandTotalLoader
				locale={this.props.intl.locale}
				apiURL={this.props.apiURL}
				booking={this.getDataInCorrectFormat()}
				onUpdateError={this.handlePricingError.bind(this)}
				onUpdateStart={this.handlePricingUpdate.bind(this)}/>
		);
	}

	renderDiscountCodeInputWhenAvailable() {
		if (!this.hasCompletedStep1() && !this.state.data.discountCode) {
			return null;
		}

		return (
			<DiscountCodeInput
				className="booking-form__discount-code"
				value={this.state.data.discountCode}
				onChange={discountCode => this.handleDataChanged({discountCode})}/>
		);
	}

	renderSubmitButton() {
		const hasCompletedStep = this.state.step === 1 ? this.hasCompletedStep1() : this.hasCompletedStep2();
		const disabled = this.state.isSubmitting || !hasCompletedStep;

		return (
			<Button align="right" type="submit" className="booking-form__submit" disabled={disabled}>
				<FormattedMessage id={this.state.step === 1 ? 'NextStep' : 'Submit'}/>
			</Button>
		);
	}

	handleSubmit(event) {
		event.preventDefault();

		if (this.state.step === 1) {
			this.setState({step: 2});
			return;
		}

		this.submit();
	}

	handleDataChanged(data) {
		this.setState({data: {...this.state.data, ...data}});
	}

	handleChildFieldsToggled(checked) {
		this.setState({showChildrenFields: checked});
	}

	handleNoneDefaultLocationFieldsToggled(checked) {
		this.setState({showNoneDefaultLocationFields: checked});
	}

	handleBackRequested() {
		this.setState({step: 1});
	}

	handlePricingUpdate() {
		this.setState({errorFields: {}});
	}

	handlePricingError(response) {
		this.setState({errorFields: response.fields});
	}

	submit() {
		if (this.state.isSubmitting) {
			return;
		}

		this.setState({isSubmitting: true, errorMessage: '', errorFields: {}});

		request
			.post(this.props.apiURL + '/bookings')
			.set('Accept', 'application/json')
			.set('Accept-Language', this.props.intl.locale)
			.send(this.getDataInCorrectFormat())
			.end((error, response) => {
				this.setState({isSubmitting: false});

				if (error || !response.ok) {
					this.handleSubmitFailed(error.response.body);
					return;
				}

				this.handleSubmittedSuccessfully(response.body);
			});
	}

	getDataInCorrectFormat() {
		const s = this.state.data;
		const departure = new Date(s.date.getTime());
		departure.setHours(s.time.getHours(), s.time.getMinutes(), s.time.getSeconds(), 0);

		const data = {
			referrer: this.props.referrer,
			departure: formatDateAsYYYYMMDDHHMM(departure),
			duration: s.duration,
			start_location: s.startLocation === 'userDefined' ? s.userDefinedStartLocation : s.startLocation,
			end_location: s.endLocation === 'userDefined' ? s.userDefinedEndLocation : s.endLocation,
			adults: s.adults,
			children: s.children ? s.children : 0,
			toddlers: s.toddlers ? s.toddlers : 0,

			first_name: s.firstName,
			last_name: s.lastName,
			email: s.email,
			phone_number: s.phoneNumber,
			comments: s.comments,
			discount_code: s.discountCode,
		};

		if (s.arrangementType && s.arrangementType !== 'none') {
			data.arrangement_type = s.arrangementType;
		}

		return data;
	}

	handleSubmittedSuccessfully() {
		this.setState({submittedSuccessfully: true, data: initialData, step: 1});
	}

	handleSubmitFailed(response) {
		const error = response.error ? response.error : this.props.intl.formatMessage({id: 'Failed'});

		this.setState({
			errorMessage: error,
			errorFields: response.fields,
			step: this.containsErrorInStep1(response.fields) ? 1 : 2,
		});
	}

	hasCompletedStep1() {
		const data = this.state.data;
		const requiredFields = ['arrangementType', 'date', 'time', 'duration', 'numPassengers', 'startLocation', 'endLocation'];
		const firstMissingField = requiredFields.find((fieldName) => !data[fieldName]);
		if (firstMissingField) {
			return false;
		}

		if (data.startLocation == USER_DEFINED_LOCATION_ID && data.userDefinedStartLocation === '') {
			return false;
		}

		if (data.endLocation == USER_DEFINED_LOCATION_ID && data.userDefinedEndLocation === '') {
			return false;
		}

		return true;
	}

	hasCompletedStep2() {
		const requiredFields = ['firstName', 'lastName', 'email', 'phoneNumber', 'comments', 'acceptedTerms']

		return !requiredFields.find((fieldName) => !this.state.data[fieldName]);
	}

	containsErrorInStep1(errors) {
		if (!errors) {
			return false;
		}

		const step1Fields = ['arrangement_type', 'adults', 'children', 'toddlers', 'departure', 'duration', 'start_location', 'end_location'];

		return !!Object.keys(errors).find(field => step1Fields.indexOf(field) >= 0);
	}

	willChangeArrangementTypeToSet(currentData, nextData) {
		if (!nextData.arrangementType) {
			return false;
		}

		return !currentData.arrangementType;
	}

	getArrangementById(id) {
		return this.props.arrangementTypes.find(at => at.id === id);
	}

	trackProgress(segment) {
		if (typeof ga !== 'function') {
			return;
		}

		ga('send', {
			hitType: 'pageview',
			page: '/bookingform/' + segment,
		});
	}
}

BookingForm.propTypes = {
	apiURL: PropTypes.string.isRequired,
	initialArrangementType: PropTypes.string,
	referrer: PropTypes.string,
	discountCode: PropTypes.string,
	arrangementTypes: PropTypes.arrayOf(ArrangementType).isRequired,
	locations: PropTypes.arrayOf(Location).isRequired,
	intl: Intl.isRequired,
};

export default injectIntl(BookingForm);
