// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./pattern.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "#shu-booking-form .grand-total,#shu-booking-form .grand-total__loading{position:relative;margin-top:1em;padding:0.5em 0.75em;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");color:#036}#shu-booking-form .grand-total__expand{position:absolute;bottom:0.5em;right:0.75em;color:#036;font-weight:bold}#shu-booking-form .grand-total--expanded .grand-total__expand{bottom:auto;top:0.5em}#shu-booking-form .grand-total__title{font-size:0.8em}#shu-booking-form .grand-total__total{font-weight:bold}#shu-booking-form .grand-total__lines-container{overflow:hidden;position:relative}#shu-booking-form .grand-total__lines{margin-top:1.5em;border:0;width:100%}#shu-booking-form .grand-total__line--total td{border-top:1px solid #036;padding-top:0.5em}#shu-booking-form .grand-total__line__title{font-weight:bold}#shu-booking-form .grand-total__line__amount{font-weight:bold;text-align:right}#shu-booking-form .grand-total__line__description{padding-bottom:0.5em}\n", ""]);
// Exports
module.exports = exports;
