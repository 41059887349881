import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './svg-icon.scss';

const icons = require.context('./icons', false, /\.svg$/);

let fetchIconByName = function(name) {
	return icons('./' + name + '.svg');
};

class SVGIcon extends Component {

	render() {
		const iconSVG = fetchIconByName(this.props.name).default;
		const classes = classNames({
			'svg-icon': true,
			['svg-icon--' + this.props.name]: true,
			[this.props.className]: ! ! this.props.className
		});

		return (
			<span className={classes} dangerouslySetInnerHTML={{__html: iconSVG}}/>
		);
	}
}

SVGIcon.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string
};

export default SVGIcon;
