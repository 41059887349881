export default {

	getCalendarStart(monthDate) {
		return this.findFirstMondayBeforeDate(this.getStartOfMonth(monthDate));
	},

	getCalendarEnd(monthDate) {
		return this.findFirstSundayAfterDate(this.getEndOfMonth(monthDate));
	},

	getStartOfMonth(monthDate) {
		const date = new Date(monthDate);
		date.setDate(1);
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		date.setMilliseconds(0);

		return date;
	},

	getEndOfMonth(monthDate) {
		const date = this.getStartOfMonth(monthDate);
		date.setMonth(date.getMonth() + 1);
		date.setSeconds(- 1);

		return date;
	},

	findFirstMondayBeforeDate(date) {
		const monday = new Date(date);
		while (monday.getDay() !== 1) {
			monday.setDate(monday.getDate() - 1);
		}

		return monday;
	},

	findFirstSundayAfterDate(date) {
		const sunday = new Date(date);
		while (sunday.getDay() !== 0) {
			sunday.setDate(sunday.getDate() + 1);
		}

		return sunday;
	}
};