import React, { Component } from 'react';
import {injectIntl} from 'react-intl';
import Intl from '../../../../../../../../../shared/shapes/Intl';

const randomMonday = new Date(2015, 7, 17, 0, 0, 0);

class DayNames extends Component {

	render() {
		return (
			<thead className="day-names">
			<tr>{this.renderNames(new Date(randomMonday))}</tr>
			</thead>
		);
	}

	renderNames(startDate) {
		const names = [];
		const dayNamesString = this.props.intl.formatMessage({id: 'TripDetails.Date.DayNames'});
		const dayNames = dayNamesString.split(',').map(d => d.trim());

		for (let i = 0; i < 7; i ++) {
			names.push(
				<th key={i} className="day-names__day">
					{dayNames[startDate.getDay()]}
				</th>
			);

			startDate.setDate(startDate.getDate() + 1);
		}

		return names;
	}
}

DayNames.propTypes = {
	intl: Intl.isRequired,
};

export default injectIntl(DayNames);
