import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './input.scss';

class Input extends Component {

	render() {
		const classes = classNames({
			'input': true,
			'input--disabled': this.props.disabled,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<input {...this.props} className={classes}/>
		);
	}
}

Input.propTypes = {
	disabled: PropTypes.bool,
	className: PropTypes.bool,
};

Input.defaultProps = {
	type: 'text',
	value: ''
};

export default Input;