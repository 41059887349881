import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import Select from 'components/Select/Select';
import Intl from 'shapes/Intl';

class TimePickerSelect extends Component {

	render() {
		return (
			<Select name={this.props.name}
				options={this.getSelectOptions(this.props.earliest, this.props.latest, this.props.interval)}
				value={this.props.value ? this.props.value.toTimeString() : null}
				onChange={this.handleChange.bind(this)}/>
		);
	}

	handleChange(event) {
		if (this.props.onChange) {
			const value = event.target.value;
			this.props.onChange(new Date(this.props.earliest.toDateString() + ' ' + value));
		}
	}

	getSelectOptions(earliest, latest, interval) {
		const earliestTime = earliest.getTime();
		const latestTime = latest.getTime();
		const intervalTime = interval * 60 * 1000;

		let time = earliestTime;
		const options = [];
		while (time <= latestTime) {
			const timeDate = new Date(time);
			options.push({
				value: timeDate.toTimeString(),
				label: this.props.intl.formatTime(timeDate),
			});
			time += intervalTime;
		}

		options.unshift({value: null, label: this.props.intl.formatMessage({id: 'TripDetails.Time.Default'})});

		return options;
	}
}

TimePickerSelect.propTypes = {
	name: PropTypes.string.isRequired,
	earliest: PropTypes.instanceOf(Date).isRequired,
	latest: PropTypes.instanceOf(Date).isRequired,

	/**
	 * Interval between times in minutes
	 */
	interval: PropTypes.number.isRequired,
	value: PropTypes.instanceOf(Date),
	onChange: PropTypes.func,

	intl: Intl.isRequired,
};

export default injectIntl(TimePickerSelect);
