import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SVGIcon from 'components/SVGIcon/SVGIcon';
import Option from 'shapes/Option.js';

import './select.scss';

const valueShape = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

class Select extends Component {

	static propTypes = {
		name: PropTypes.string.isRequired,
		options: PropTypes.arrayOf(Option).isRequired,
		placeholder: PropTypes.string,
		value: valueShape,
		align: PropTypes.oneOf(['left', 'center', 'right']),
		className: PropTypes.string,
		onChange: PropTypes.func,
	};

	static defaultProps = {
		value: '',
		align: 'left',
	};

	render() {
		const classes = classNames({
			'select': true,
			'select--align-': this.props.align,
			[this.props.className]: ! ! this.props.className,
		});

		const selectClasses = classNames({
			'select__select': true,
		});

		return (
			<div className={classes}>
				<SVGIcon name="arrow-down" className="select__icon"/>

				<select className={selectClasses}
					onChange={this.props.onChange}
					name={this.props.name}
					value={this.props.value ? this.props.value : ''}>

					{this.renderPlaceholder()}
					{this.props.options.map(this.renderOption)}
				</select>
			</div>
		);
	}

	renderPlaceholder() {
		if (! this.props.placeholder) {
			return null;
		}

		return this.renderOption({
			value: '',
			label: this.props.placeholder,
		});
	}

	renderOption(option) {
		return (
			<option value={option.value} key={option.value} disabled={option.isDisabled}>
				{option.label}
			</option>
		);
	}
}

export default Select;