import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './text-area.scss';

class TextArea extends Component {

	render() {
		const {className, ...props} = this.props;
		const classes = classNames({
			'textarea': true,
			[className]: ! ! className,
		});

		return (
			<textarea {...props} className={classes}/>
		);
	}
}

TextArea.propTypes = {
	name: PropTypes.string.isRequired,
	className: PropTypes.string,
	onChange: PropTypes.func,
	value: PropTypes.string,
};

export default TextArea;