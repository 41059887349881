import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';
import memoize from 'memoize-one';

import Intl from 'shapes/Intl';
import Select from 'components/Select/Select';
import { isWeekend } from 'DateHelper.js';

class SelectDatePicker extends Component {

	static propTypes = {
		name: PropTypes.string.isRequired,
		earliest: PropTypes.instanceOf(Date).isRequired,
		latest: PropTypes.instanceOf(Date).isRequired,
		value: PropTypes.instanceOf(Date),
		onChange: PropTypes.func,
		disableWeekends: PropTypes.bool,
		intl: Intl.isRequired,
	};

	static defaultProps = {
		disableWeekends: false,
	};

	constructor(props) {
		super(props);

		this.getSelectOptions = memoize(this.getSelectOptions);
	}

	render() {
		const earliest = this.props.earliest;
		const latest = this.props.latest;
		const disableWeekends = this.props.disableWeekends;

		return (
			<Select name={this.props.name}
				options={this.getSelectOptions(earliest, latest, disableWeekends)}
				value={this.props.value ? this.props.value.toDateString() : ''}
				onChange={this.handleChange.bind(this)}/>
		);
	}

	handleChange(event) {
		if (this.props.onChange) {
			this.props.onChange(new Date(event.target.value));
		}
	}

	/**
	 * @param {Date} earliest
	 * @param {Date} latest
	 * @return {Array}
	 */
	getSelectOptions(earliest, latest, disableWeekends = false) {
		earliest.setHours(0);
		earliest.setMinutes(0);
		earliest.setSeconds(0);
		earliest.setMilliseconds(0);

		// Generate dates
		const currentDate = new Date(earliest.getTime());
		const latestDate = new Date(latest.getTime());

		const options = [];
		while (currentDate.getTime() < latestDate.getTime()) {
			if (! disableWeekends || ! isWeekend(currentDate)) {
				options.push({
					value: currentDate.toDateString(),
					label: this.props.intl.formatDate(currentDate, {day: 'numeric', month: 'long', year: 'numeric'}),
				});
			}

			currentDate.setDate(currentDate.getDate() + 1);
		}

		options.unshift({value: '', label: this.props.intl.formatMessage({id: 'TripDetails.Date.Default'})});

		return options;
	}
}

export default injectIntl(SelectDatePicker);
