import React, { Component } from 'react';
import request from 'superagent';
import PropTypes from 'prop-types';
import {FormattedMessage, injectIntl} from 'react-intl';

import Intl from 'shapes/Intl';

import BookingForm from './BookingForm';

class BookingFormLoader extends Component {

	constructor(props) {
		super(props);

		this.state = {
			arrangementTypes: null,
			locations: null,
			loadingError: '',
		};
	}

	componentDidMount() {
		this.loadLocations();
		this.loadArrangementTypes();
	}

	render() {
		if (this.state.loadingError) {
			return this.renderLoadingError();
		}

		if (! this.hasLoadedAllData()) {
			return this.renderLoading();
		}

		return (
			<BookingForm
				apiURL={this.props.apiURL}
				initialArrangementType={this.props.initialArrangementType}
				arrangementTypes={this.state.arrangementTypes}
				referrer={this.props.referrer}
				discountCode={this.props.discountCode}
				locations={this.state.locations}/>
		);
	}

	renderLoading() {
		return (<FormattedMessage id="Loading"/>);
	}

	renderLoadingError() {
		return (
			<div>
				<FormattedMessage id="Failed"/>
				<br/>
				{this.state.loadingError}
			</div>
		);
	}

	loadLocations() {
		request
			.get(this.props.apiURL + '/locations')
			.set('Accept', 'application/json')
			.set('Accept-Language', this.props.locale)
			.end((error, response) => {
				if (error || ! response.ok) {
					this.setState({loadingError: error});
					return;
				}

				this.setState({locations: response.body});
			});
	}

	loadArrangementTypes() {
		request
			.get(this.props.apiURL + '/arrangements')
			.set('Accept', 'application/json')
			.set('Accept-Language', this.props.locale)
			.end((error, response) => {
				if (error || ! response.ok) {
					this.setState({loadingError: error});
					return;
				}

				const arrangementTypes = response.body;
				arrangementTypes.unshift({
					id: 'none',
					name: this.props.intl.formatMessage({id: 'TripDetails.Arrangement.None'}),
					minimumDuration: null,
					maximumDuration: null,
				});

				this.setState({arrangementTypes: arrangementTypes});
			});
	}

	hasLoadedAllData() {
		return this.state.arrangementTypes && this.state.locations;
	}
}

BookingFormLoader.propTypes = {
	apiURL: PropTypes.string.isRequired,
	initialArrangementType: PropTypes.string,
	referrer: PropTypes.string,
	discountCode: PropTypes.string,
	locale: PropTypes.string,
	intl: Intl.isRequired,
};

export default injectIntl(BookingFormLoader);
