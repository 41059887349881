function leftPad(number, targetLength = 2) {
	let output = number + '';
	while (output.length < targetLength) {
		output = '0' + output;
	}

	return output;
}

export default leftPad;
