import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Option from 'shapes/Option.js';
import Icon from 'components/SVGIcon/SVGIcon.jsx';
import './stepper.scss';

const valueShape = PropTypes.oneOfType([PropTypes.string, PropTypes.number]);

class Stepper extends Component {

	static propTypes = {
		options: PropTypes.arrayOf(Option).isRequired,
		value: valueShape,
		loop: PropTypes.bool,
		mode: PropTypes.oneOf(['horizontal', 'vertical', 'compact']),
		onChange: PropTypes.func,
		className: PropTypes.string,
		hidePreviousArrow: PropTypes.bool,
		hideNextArrow: PropTypes.bool,
	};

	static defaultProps = {
		loop: false,
		mode: 'vertical',
		hidePreviousArrow: false,
		hideNextArrow: false,
	};

	render() {
		const classes = classNames({
			'stepper': true,
			['stepper--' + this.props.mode]: true,
			[this.props.className]: ! ! this.props.className
		});

		const valueIndex = this.getValueIndex();

		return (
			<div className={classes}>
				<a href="#"
					className={this.getButtonClasses({
						isPrevious: true,
						isVisible: ! this.props.hidePreviousArrow && this.isPreviousButtonVisible(valueIndex),
					})}
					onClick={this.handlePreviousButtonClick.bind(this)}>

					<Icon name={this.props.mode !== 'horizontal' ? 'arrow-up' : 'arrow-left'}/>
				</a>

				<div className="stepper__options-wrapper">
					<ul className="stepper__options"
						style={{transform: 'translateY(-' + (2 * valueIndex) + 'em)'}}>
						{this.renderOptions()}
					</ul>
				</div>

				<a href="#"
					className={this.getButtonClasses({
						isPrevious: false,
						isVisible: ! this.props.hideNextArrow && this.isNextButtonVisible(valueIndex),
					})}
					onClick={this.handleNextButtonClick.bind(this)}>
					<Icon name={this.props.mode !== 'horizontal' ? 'arrow-down' : 'arrow-right'}/>
				</a>
			</div>
		);
	}

	renderOptions() {
		return this.props.options.map(option => {
			return (
				<li key={option.value} className="stepper__option">
					{option.label}
				</li>
			);
		});
	}

	triggerOnChange(newValue) {
		if (this.props.onChange) {
			this.props.onChange(newValue);
		}
	}

	handlePreviousButtonClick(event) {
		event.preventDefault();

		const value = this.getValueByIndex(this.getPreviousIndex());
		this.triggerOnChange(value);
	}

	handleNextButtonClick(event) {
		event.preventDefault();

		const value = this.getValueByIndex(this.getNextIndex());
		this.triggerOnChange(value);
	}

	getValueByIndex(index) {
		const option = this.props.options[index];
		return option ? option['value'] : null;
	}

	getPreviousIndex() {
		const index = this.getValueIndex();

		if (index === 0) {
			return this.props.loop ? this.props.options.length - 1 : index;
		}

		return index - 1;
	}

	getNextIndex() {
		const index = this.getValueIndex();

		if (index === this.props.options.length - 1) {
			return this.props.loop ? 0 : index;
		}

		return index + 1;
	}

	getValueIndex() {
		const index = this.props.options.findIndex(option => this.props.value === option.value);
		if (index < 0) {
			return 0;
		}

		return index;
	}

	getButtonClasses(options) {
		return classNames({
			'stepper__button': true,
			'stepper__button--hidden': ! options.isVisible,
			'stepper__button--next': ! options.isPrevious,
			'stepper__button--previous': options.isPrevious,
		});
	}

	isPreviousButtonVisible(valueIndex) {
		if (this.props.options.length < 2) {
			return false;
		}

		if (this.props.loop) {
			return true;
		}

		return valueIndex > 0;
	}

	isNextButtonVisible(valueIndex) {
		if (this.props.options.length < 2) {
			return false;
		}

		if (this.props.loop) {
			return true;
		}

		return valueIndex < this.props.options.length - 1;
	}
}

export default Stepper;