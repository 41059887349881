import React from 'react';
import ReactDOM from 'react-dom';
import 'jspolyfill-array.prototype.findIndex';
import 'jspolyfill-array.prototype.find';
import 'object.keys';
import { AppContainer } from 'react-hot-loader';

import BookingFormLoader from './screens/BookingForm/BookingFormLoader.jsx';
import LocalizedApp from './LocalizedApp';
import parseQueryString from './util/url/parseQueryString';

const query = parseQueryString(window.location.search);

function render() {
	console.log('[SHU] Rendering form');
	const apiURL = window.shuFormAPIURL ? window.shuFormAPIURL : '/api';
	const el = document.querySelector('#shu-booking-form');
	if (! el) {
		throw new Error('Could not find booking form container. Expected div with `booking-form` id.')
	}

	const Component = (
		<AppContainer>
			<LocalizedApp locale={el.getAttribute('data-locale')}>
				{(locale) => (
					<BookingFormLoader
						locale={locale}
						discountCode={query.code}
						apiURL={apiURL}
						initialArrangementType={el.getAttribute('data-arrangement-type')}
						referrer={el.getAttribute('data-referrer')}/>
				)}
			</LocalizedApp>
		</AppContainer>
	);

	ReactDOM.render(Component, el);
}

render();
