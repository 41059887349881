import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

import nl2br from 'util/nl2br';

import './grand-total.scss';

class GrandTotal extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			expanded: false,
		};
	}

	render() {
		const classes = classNames({
			'grand-total': true,
			'grand-total--expanded': this.state.expanded,
		});

		return (
			<div className={classes}>
				<a href="#" className="grand-total__expand" onClick={this.handleExpandToggled.bind(this)}>
					<FormattedMessage id={'GrandTotal.Toggle.' + (this.state.expanded ? 'Opened' : 'Closed')}/>
				</a>

				<div className="grand-total__title"><FormattedMessage id="GrandTotal.Title"/></div>
				<div className="grand-total__total"><FormattedMessage id="GrandTotal.Total"/>: {this.props.total}</div>

				<div className="grand-total__lines-container">
					<TransitionGroup>
						{this.renderLinesWhenToggled()}
					</TransitionGroup>
				</div>
			</div>
		);
	}

	renderLinesWhenToggled() {
		if (! this.state.expanded) {
			return null;
		}

		return (
			<CSSTransition classNames="transition-slide-down" timeout={{enter: 1000, exit: 500}}>
				<table className="grand-total__lines" cellPadding={0} cellSpacing={0}>
					<tbody>
					{this.renderLines()}
					<tr className="grand-total__line grand-total__line--total">
						<td className="grand-total__line__title"><FormattedMessage id="GrandTotal.Total"/>:</td>
						<td className="grand-total__line__amount">{this.props.total}</td>
					</tr>
					</tbody>
				</table>
			</CSSTransition>
		);
	}

	renderLines() {
		return this.props.lines.map((line, index) => [
			<tr className="grand-total__line" key={'line-' + index}>
				<td className="grand-total__line__title">{line.title}</td>
				<td className="grand-total__line__amount">{line.amount}</td>
			</tr>,
			<tr className="grand-total__line-description" key={'desc-' + index}>
				<td colSpan={2} className="grand-total__line__description">
					{nl2br(line.description)}
				</td>
			</tr>
		]);
	}

	handleExpandToggled(event) {
		event.preventDefault();
		this.setState({expanded: ! this.state.expanded});
	}
}

GrandTotal.propTypes = {
	total: PropTypes.string,
	lines: PropTypes.arrayOf(PropTypes.shape({
		title: PropTypes.string,
		amount: PropTypes.string,
		description: PropTypes.string,
	})),
};

export default GrandTotal;
