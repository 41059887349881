import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import Select from 'components/Select/Select';
import Intl from 'shapes/Intl';

class DurationPicker extends Component {

	render() {
		return (
			<Select name={this.props.name}
				options={this.getDurationOptions()}
				value={this.props.value}
				onChange={this.handleChange.bind(this)}/>
		);
	}

	handleChange(event) {
		if (this.props.onChange) {
			this.props.onChange(parseFloat(event.target.value));
		}
	}

	getDurationOptions() {
		const options = [{
			label: this.props.intl.formatMessage({id: 'TripDetails.Duration.Default'}),
			value: '',
			isDisabled: true
		}];

		for (let option = this.props.minimum; option <= this.props.maximum; option += this.props.interval) {
			const label = this.props.intl.formatMessage({id: 'TripDetails.Duration.Value'}, {duration: option});

			options.push({
				label: label,
				value: option
			});
		}

		return options;
	}

}

DurationPicker.propTypes = {
	name: PropTypes.string.isRequired,
	minimum: PropTypes.number.isRequired,
	default: PropTypes.number,
	maximum: PropTypes.number.isRequired,
	interval: PropTypes.number.isRequired,
	value: PropTypes.number,
	onChange: PropTypes.func,
	intl: Intl.isRequired,
};

DurationPicker.defaultProps = {
	minimum: 1.5,
	interval: 0.5,
};

export default injectIntl(DurationPicker);
