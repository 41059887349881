import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CalendarRangeFinder from './utils/CalendarRangeFinder.js';
import DayNames from './DayNames';
import WeeksAndDays from './WeeksAndDays';
import './day-picker.scss';

class DayPicker extends Component {

	static propTypes = {
		month: PropTypes.instanceOf(Date).isRequired,
		earliest: PropTypes.instanceOf(Date),
		latest: PropTypes.instanceOf(Date),
		value: PropTypes.instanceOf(Date),
		disableWeekends: PropTypes.bool,
		onChange: PropTypes.func
	};

	render() {
		const firstDayOfMonth = CalendarRangeFinder.getStartOfMonth(this.props.month);
		const lastDayOfMonth = CalendarRangeFinder.getEndOfMonth(this.props.month);
		const firstDay = CalendarRangeFinder.findFirstMondayBeforeDate(firstDayOfMonth);
		const lastDay = CalendarRangeFinder.findFirstSundayAfterDate(lastDayOfMonth);

		return (
			<div className="day-picker">
				<table className="day-picker__table" onClick={this.handleClick.bind(this)}>
					<DayNames/>
					<WeeksAndDays
						earliest={this.props.earliest}
						latest={this.props.latest}
						current={this.props.value}
						disableWeekends={this.props.disableWeekends}
						start={firstDay}
						end={lastDay}
						availableStart={firstDayOfMonth}
						availableEnd={lastDayOfMonth}/>
				</table>
			</div>
		);
	}

	/**
	 * On Click is added to the root component for performance reasons.
	 * @param event
	 */
	handleClick(event) {
		event.preventDefault();

		if (! this.isValidDateButton(event.target)) {
			return;
		}

		if (this.props.onChange) {
			this.props.onChange(new Date(event.target.dataset.date));
		}
	}

	isValidDateButton(target) {
		if (! target.classList.contains('day-picker__day')) {
			return false;
		}

		return ! target.classList.contains('day-picker__day--not-available');
	}
}

export default DayPicker;