import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SVGIcon from 'components/SVGIcon/SVGIcon.jsx';

import './field-wrapper.scss';

class FieldWrapper extends Component {

	static propTypes = {
		children: PropTypes.node.isRequired,
		label: PropTypes.string.isRequired,
		className: PropTypes.string,
		errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
		noLabelElement: PropTypes.bool,
	};

	render() {
		const classes = classNames({
			'field-wrapper': true,
			'field-wrapper--has-error': ! ! this.props.errors,
			[this.props.className]: ! ! this.props.className
		});

		if (this.props.noLabelElement) {
			return (<span className={classes}>{this.renderLabelAndField()}</span>)
		}

		return (<label className={classes}>{this.renderLabelAndField()}</label>);
	}

	renderLabelAndField() {
		return [
			(
				<span className="field-wrapper__label" key="label">
					{this.props.label}
					{this.renderWarningSignWhenHasError()}
				</span>
			),
			this.props.children,
			this.renderErrorsIfAny()
		];
	}

	renderErrorsIfAny() {
		if (! this.props.errors) {
			return null;
		}

		if (typeof this.props.errors === 'string') {
			return this.renderError(this.props.errors, 0);
		}

		return this.props.errors.map(this.renderError.bind(this));
	}

	renderError(message, index) {
		return (
			<span className="field-wrapper__error" key={index}>{message}</span>
		);
	}

	renderWarningSignWhenHasError() {
		if (! this.props.errors) {
			return null;
		}

		return (
			<SVGIcon name="warning" className="field-wrapper__warning-icon" key="warning"/>
		);
	}
}

export default FieldWrapper;