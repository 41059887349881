import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import ArrangementType from 'shapes/ArrangementType.js';
import Select from 'components/Select/Select';
import Intl from 'shapes/Intl';

class ArrangementPicker extends Component {

	render() {
		return (
			<Select name={this.props.name}
				options={this.getArrangementTypeOptions()}
				value={this.props.value}
				onChange={this.handleChange.bind(this)}/>
		);
	}

	handleChange(event) {
		if (this.props.onChange) {
			this.props.onChange(event.target.value);
		}
	}

	getArrangementTypeOptions() {
		const arrangementTypes = this.props.arrangementTypes.map(arrangement => {
			return {
				value: arrangement.id,
				label: arrangement.name,
				isDisabled: arrangement.isDisabled ? arrangement.isDisabled : false
			};
		});

		arrangementTypes.unshift({
			label: this.props.intl.formatMessage({id: 'TripDetails.Arrangement.Default'}),
			value: '',
			isDisabled: true
		});

		return arrangementTypes;
	}
}

ArrangementPicker.propTypes = {
	name: PropTypes.string.isRequired,
	arrangementTypes: PropTypes.arrayOf(ArrangementType).isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func,
	intl: Intl.isRequired,
};

export default injectIntl(ArrangementPicker);
