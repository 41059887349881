import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FormattedMessage, injectIntl} from 'react-intl';

import Input from 'components/Input/Input';
import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import TextArea from 'components/TextArea/TextArea';
import SVGIcon from 'components/SVGIcon/SVGIcon';
import Intl from '../../../../shared/shapes/Intl';
import CheckBox from '../../../../shared/components/CheckBox/CheckBox';

import './contact-details.scss';

class ContactDetails extends Component {

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}

	render() {
		const classes = classNames({
			'contact-details': true,
			[this.props.className]: ! ! this.props.className,
		});

		const formatMessage = this.props.intl.formatMessage;

		return (
			<div className={classes}>
				<a href="#" className="contact-details__back" onClick={this.handleBackClicked.bind(this)}>
					<SVGIcon name="arrow-left" className="contact-details__back__icon"/>
					<span className="contact-details__back__label">
						<FormattedMessage id="ContactDetails.Back"/>
					</span>
				</a>

				<FieldWrapper label={formatMessage({id: 'ContactDetails.FirstName'})} errors={this.props.fieldsWithError.first_name}>
					<Input name="firstName" value={this.props.firstName} onChange={this.handleChange}
						autoComplete="given-name"/>
				</FieldWrapper>
				<FieldWrapper label={formatMessage({id: 'ContactDetails.LastName'})} errors={this.props.fieldsWithError.last_name}>
					<Input name="lastName" value={this.props.lastName} onChange={this.handleChange}
						autoComplete="family-name"/>
				</FieldWrapper>
				<FieldWrapper label={formatMessage({id: 'ContactDetails.PhoneNumber'})} errors={this.props.fieldsWithError.phone_number}>
					<Input name="phoneNumber" value={this.props.phoneNumber} onChange={this.handleChange} type="tel"
						autoComplete="tel"/>
				</FieldWrapper>
				<FieldWrapper label={formatMessage({id: 'ContactDetails.Email'})} errors={this.props.fieldsWithError.email}>
					<Input name="email" value={this.props.email} onChange={this.handleChange} type="email"
						autoComplete="email"/>
				</FieldWrapper>
				<FieldWrapper label={formatMessage({id: 'ContactDetails.Comments'})} errors={this.props.fieldsWithError.comments}>
					<TextArea name="comments" rows={3} value={this.props.comments} onChange={this.handleChange}/>
				</FieldWrapper>

				<CheckBox name="acceptedTerms" checked={this.props.acceptedTerms} onChange={(checked) => {
					this.updatedCallback('acceptedTerms', checked);
				}}>
					<span className="contact_details__terms-label" dangerouslySetInnerHTML={{
						__html: formatMessage({id: 'ContactDetails.AcceptTerms'})
					}}/>
				</CheckBox>
			</div>
		);
	}

	updatedCallback(name, value) {
		if (this.props.onUpdate) {
			this.props.onUpdate({[name]: value});
		}
	}

	handleChange(event) {
		this.updatedCallback(event.target.name, event.target.value);
	}

	handleBackClicked(event) {
		event.preventDefault();

		if (this.props.onBackRequested) {
			this.props.onBackRequested();
		}
	}
}

ContactDetails.propTypes = {
	firstName: PropTypes.string,
	lastName: PropTypes.string,
	phoneNumber: PropTypes.string,
	email: PropTypes.string,
	comments: PropTypes.string,
	acceptedTerms: PropTypes.bool,

	className: PropTypes.string,
	onUpdate: PropTypes.func,
	onBackRequested: PropTypes.func,
	fieldsWithError: PropTypes.object,
	intl: Intl.isRequired,
};

ContactDetails.defaultProps = {
	fieldsWithError: {},
};

export default injectIntl(ContactDetails);
