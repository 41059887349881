import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './button-list.scss';

class ButtonList extends Component {

	render() {
		const classes = classNames({
			'button-list': true,
			'button-list--multiple-children': this.props.children.length > 1,
			['button-list--align-' + this.props.align]: true,
			[this.props.className]: ! ! this.props.className,
		});

		return (
			<div className={classes}>
				{this.props.children}
			</div>
		);
	}
}

ButtonList.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string,
	align: PropTypes.oneOf(['left', 'center', 'right']),
};

ButtonList.defaultProps = {
	align: 'left',
};

export default ButtonList;