import React from 'react';

const newlineRegex = /(\r\n|\r|\n)/g;

export default (string) => {
	if (typeof string === 'number') {
		return string;
	}

	if (typeof string !== 'string') {
		return '';
	}

	return string.split(newlineRegex).map(function(line, index) {
		if (line.match(newlineRegex)) {
			return React.createElement('br', {key: index});
		}

		return line;
	});
}