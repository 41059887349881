import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import classNames from 'classnames';
import {FormattedMessage, injectIntl} from 'react-intl';

import Intl from 'shapes/Intl.js';
import Location from 'shapes/Location.js';
import ArrangementType from 'shapes/ArrangementType.js';

import FieldWrapper from 'components/FieldWrapper/FieldWrapper';
import Input from 'components/Input/Input';
import CheckBox from 'components/CheckBox/CheckBox';
import { USER_DEFINED_LOCATION_ID } from 'Constants.js';

import DatePicker from './components/DatePicker/DatePicker';
import TimePicker from './components/TimePicker/TimePicker';
import LocationPicker from './components/LocationPicker/LocationPicker';
import DurationPicker from './components/DurationPicker/DurationPicker';
import ArrangementPicker from './components/ArrangementPicker/ArrangementPicker';

import './trip-details.scss';

class TripDetails extends Component {

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
	}

	render() {
		const classes = classNames({
			'trip-details': true,
			[this.props.className]: ! ! this.props.className,
		});

		const now = new Date();
		const nextYear = new Date();
		nextYear.setFullYear(now.getFullYear() + 1);

		const formatMessage = this.props.intl.formatMessage;

		return (
			<div className={classes}>
				<p className="trip-details__intro">
					<FormattedMessage id="TripDetails.Intro"/>
				</p>

				<FieldWrapper label={formatMessage({id: 'TripDetails.Arrangement.Label'})} errors={this.props.fieldsWithError.arrangement_type}>
					<ArrangementPicker
						name="arrangementType"
						arrangementTypes={this.props.arrangementTypes}
						value={this.props.arrangementType}
						onChange={arrangementType => this.handleChange({arrangementType})}/>
				</FieldWrapper>

				<FieldWrapper label={formatMessage({id: 'TripDetails.Date.Label'})} errors={this.props.fieldsWithError.departure}>
					<DatePicker
						name="date"
						months={1}
						earliest={now}
						latest={nextYear}
						initialValue={this.props.date}
						onChange={date => this.handleChange({date})}/>
				</FieldWrapper>

				<FieldWrapper label={formatMessage({id: 'TripDetails.Time.Label'})}>
					<TimePicker name="time"
						value={this.props.time}
						onChange={time => this.handleChange({time})}
						earliest={new Date(2017, 5, 26, 9, 0, 0)}
						latest={new Date(2017, 5, 26, 22, 0, 0)}
						interval={15}/>
				</FieldWrapper>

				<FieldWrapper label={formatMessage({id: 'TripDetails.Duration.Label'})} errors={this.props.fieldsWithError.duration}>
					<DurationPicker
						name="duration"
						minimum={1.5}
						default={1.5}
						maximum={4}
						interval={0.5}
						value={this.props.duration}
						onChange={duration => this.handleChange({duration})}/>
				</FieldWrapper>

				<FieldWrapper label={formatMessage({id: 'TripDetails.Passengers.Label'})} errors={this.props.fieldsWithError.adults}>
					<Input
						name="numPassengers"
						pattern="[0-9]*"
						type="tel"
						value={this.props.numPassengers}
						onChange={numPassengers => this.handlePassengersChanged(numPassengers)}/>
				</FieldWrapper>

				<CheckBox
					className="trip-details__children"
					name="has_children"
					checked={this.props.showChildrenFields}
					onChange={this.toggleHasChildren.bind(this)}>

					<FormattedMessage id="TripDetails.Children.Title"/>
				</CheckBox>

				{this.renderChildrenInputs()}

				<p className="trip-details__custom-location-intro">
					<FormattedMessage id="TripDetails.CustomLocation.Title"/>
				</p>

				<CheckBox
					className="trip-details__custom-location"
					name="custom_location"
					checked={this.props.showNoneDefaultLocationFields}
					onChange={this.toggleNoneDefaultLocation.bind(this)}>
					<FormattedMessage id="TripDetails.CustomLocation.Checkbox"/>
				</CheckBox>

				{this.renderNoneDefaultLocationPickers()}
			</div>
		);
	}

	renderNoneDefaultLocationPickers() {
		if (! this.props.showNoneDefaultLocationFields) {
			return null;
		}

		const formatMessage = this.props.intl.formatMessage;

		return (
			<div className="trip-details__non-default-locations">
				<FieldWrapper label={formatMessage({id: 'TripDetails.CustomLocation.StartLocation'})}>
					<LocationPicker name="startLocation"
						onChange={startLocation => this.handleChange({startLocation})}
						value={this.props.startLocation}
						locations={this.props.locations}/>
				</FieldWrapper>
				<TransitionGroup>
					{this.renderDifferentStartLocationIfRequired()}
				</TransitionGroup>

				<FieldWrapper label={formatMessage({id: 'TripDetails.CustomLocation.EndLocation'})}>
					<LocationPicker name="endLocation"
						onChange={endLocation => this.handleChange({endLocation})}
						value={this.props.endLocation}
						locations={this.props.locations}/>
				</FieldWrapper>
				<TransitionGroup>
					{this.renderDifferentEndLocationIfRequired()}
				</TransitionGroup>
			</div>
		);
	}

	renderChildrenInputs() {
		if (! this.props.showChildrenFields) {
			return null;
		}

		const formatMessage = this.props.intl.formatMessage;

		return (
			<div className="trip-details__has-children">
				<FieldWrapper label={formatMessage({id: 'TripDetails.Children.Older'})} errors={this.props.fieldsWithError.children}>
					<Input name="children"
						pattern="[0-9]*"
						type="tel"
						value={this.props.children}
						onChange={children => this.handleChildrenChanged(children)}/>
				</FieldWrapper>

				<FieldWrapper label={formatMessage({id: 'TripDetails.Children.Young'})} errors={this.props.fieldsWithError.toddlers}>
					<Input name="toddlers"
						pattern="[0-9]*"
						type="tel"
						value={this.props.toddlers}
						onChange={toddlers => this.handleToddlersChanged(toddlers)}/>
				</FieldWrapper>
			</div>
		);
	}

	renderDifferentStartLocationIfRequired() {
		if (this.props.startLocation !== USER_DEFINED_LOCATION_ID) {
			return null;
		}

		const label = this.props.intl.formatMessage({id: 'TripDetails.CustomLocation.CustomStartLocation'});

		return (
			<CSSTransition
				classNames="transition-fade"
				timeout={{enter: 1000, appear: 500, exit: 300}}
				appear enter leave>
				<FieldWrapper label={label} errors={this.props.fieldsWithError.start_location}>
					<Input name="userDefinedStartLocation" value={this.props.userDefinedStartLocation}
						onChange={event => this.handleChange({userDefinedStartLocation: event.target.value})}/>
				</FieldWrapper>
			</CSSTransition>
		);
	}

	renderDifferentEndLocationIfRequired() {
		if (this.props.endLocation !== USER_DEFINED_LOCATION_ID) {
			return null;
		}

		const label = this.props.intl.formatMessage({id: 'TripDetails.CustomLocation.CustomEndLocation'});

		return (
			<CSSTransition
				classNames="transition-fade"
				timeout={{enter: 1000, appear: 500, exit: 300}}
				appear enter leave>
				<FieldWrapper label={label} errors={this.props.fieldsWithError.end_location}>
					<Input name="userDefinedEndLocation" value={this.props.userDefinedEndLocation}
						onChange={event => this.handleChange({userDefinedEndLocation: event.target.value})}/>
				</FieldWrapper>
			</CSSTransition>
		);
	}

	toggleNoneDefaultLocation(checked) {
		if (this.props.onNoneDefaultLocationFieldsToggled) {
			this.props.onNoneDefaultLocationFieldsToggled(checked);
		}

		if (! checked) {
			const defaultLocation = this.props.locations.find(location => location.isDefault) || this.props.locations[0];

			this.handleChange({
				startLocation: defaultLocation.name,
				endLocation: defaultLocation.name,
				userDefinedStartLocation: '',
				userDefinedEndLocation: '',
			});
		}
	}

	toggleHasChildren(checked) {
		if (this.props.onChildFieldsToggled) {
			this.props.onChildFieldsToggled(checked);
		}

		if (! checked) {
			this.handleChange({
				adults: this.props.numPassengers,
				children: 0,
				toddlers: 0,
			});
		}
	}

	handleChange(newState) {
		if (this.props.onUpdate) {
			this.props.onUpdate(newState);
		}
	}

	handlePassengersChanged(event) {
		let amount = event.target.value ? parseInt(event.target.value) : undefined;
		if (isNaN(amount)) {
			amount = undefined;
		}

		const children = this.props.children ? this.props.children : 0;
		const toddlers = this.props.toddlers ? this.props.toddlers : 0;

		this.handleChange({
			numPassengers: amount,
			adults: amount - (children + toddlers),
		});
	}

	handleChildrenChanged(event) {
		let amount = event.target.value ? parseInt(event.target.value) : undefined;
		if (isNaN(amount)) {
			amount = undefined;
		}

		const children = amount ? amount : 0;
		const toddlers = this.props.toddlers ? this.props.toddlers : 0;

		this.handleChange({
			children: amount,
			adults: this.props.numPassengers - (children + toddlers),
		});
	}

	handleToddlersChanged(event) {
		let amount = event.target.value ? parseInt(event.target.value) : undefined;
		if (isNaN(amount)) {
			amount = undefined;
		}

		const children = this.props.children ? this.props.children : 0;
		const toddlers = amount ? amount : 0;

		this.handleChange({
			adults: this.props.numPassengers - (children + toddlers),
			toddlers: amount,
		});
	}
}

TripDetails.propTypes = {
	className: PropTypes.string,
	onUpdate: PropTypes.func,
	onChildFieldsToggled: PropTypes.func,
	onNoneDefaultLocationFieldsToggled: PropTypes.func,
	locations: PropTypes.arrayOf(Location).isRequired,
	arrangementTypes: PropTypes.arrayOf(ArrangementType).isRequired,

	arrangementType: PropTypes.string,
	date: PropTypes.instanceOf(Date),
	time: PropTypes.instanceOf(Date),
	duration: PropTypes.number,
	numPassengers: PropTypes.number,
	showChildrenFields: PropTypes.bool,
	adults: PropTypes.number,
	children: PropTypes.number,
	toddlers: PropTypes.number,
	startLocation: PropTypes.string,
	endLocation: PropTypes.string,
	showNoneDefaultLocationFields: PropTypes.bool,
	userDefinedStartLocation: PropTypes.string,
	userDefinedEndLocation: PropTypes.string,
	fieldsWithError: PropTypes.object,

	intl: Intl.isRequired,
};

TripDetails.defaultProps = {
	fieldsWithError: {},
};

export default injectIntl(TripDetails);
