import React, { Component } from 'react';
import PropTypes from 'prop-types';
import request from 'superagent';
import {FormattedMessage} from 'react-intl';

import GrandTotal from './GrandTotal';

class GrandTotalLoader extends Component {

	constructor(props) {
		super(props);

		this.state = {
			total: null,
			lines: null,
			loadingError: '',
			currentRequest: null,
		};
	}

	componentDidMount() {
		this.loadPrice();
	}

	componentDidUpdate(prevProps) {
		const properties = [
			'arrangement_type',
			'departure',
			'duration',
			'start_location',
			'end_location',
			'adults',
			'children',
			'toddlers',
		];

		const changed = properties.find(property => this.props.booking[property] !== prevProps.booking[property]);
		if (changed) {
			this.loadPrice();
		}
	}

	render() {
		if (this.state.loadingError) {
			return this.renderLoadingError();
		}

		if (! this.hasLoadedAllData()) {
			return this.renderLoading();
		}

		return (
			<GrandTotal
				total={this.state.total}
				lines={this.state.lines}/>
		);
	}

	renderLoading() {
		return (
			<div className="grand-total__loading">
				<FormattedMessage id="Loading"/>
			</div>
		);
	}

	renderLoadingError() {
		return (
			<div className="grand-total__loading">
				<FormattedMessage id="GrandTotal.NoPrice"/>
			</div>
		);
	}

	loadPrice() {
		if (this.state.currentRequest) {
			this.state.currentRequest.abort();
		}

		if (this.props.onUpdateStart) {
			this.props.onUpdateStart();
		}

		const newRequest = request
			.post(this.props.apiURL + '/bookings/price')
			.set('Accept', 'application/json')
			.set('Accept-Language', this.props.locale)
			.send(this.props.booking);

		this.setState({
			loadingError: null,
			currentRequest: newRequest,
		});

		newRequest.end((error, response) => {
			if (error || ! response.ok) {
				this.setState({
					loadingError: error,
					currentRequest: null,
				});
				if (this.props.onUpdateError) {
					this.props.onUpdateError(error.response.body);
				}
				return;
			}

			this.setState({
				total: response.body.total,
				lines: response.body.lines,
				currentRequest: null,
			});
		});
	}

	hasLoadedAllData() {
		return this.state.total;
	}
}

GrandTotalLoader.propTypes = {
	apiURL: PropTypes.string.isRequired,
	onUpdateStart: PropTypes.func,
	onUpdateError: PropTypes.func,
	booking: PropTypes.shape({
		departure: PropTypes.string.isRequired,
		duration: PropTypes.number.isRequired,
		start_location: PropTypes.string.isRequired,
		end_location: PropTypes.string.isRequired,
		adults: PropTypes.number.isRequired,
		children: PropTypes.number,
		toddlers: PropTypes.number,
	}).isRequired,
	locale: PropTypes.string,
};

export default GrandTotalLoader;
