import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {injectIntl} from 'react-intl';

import Intl from 'shapes/Intl'
import Location from 'shapes/Location';
import Select from 'components/Select/Select';

class LocationPicker extends Component {

	render() {
		return (
			<div className="location-picker">
				<Select options={this.getOptions()} name={this.props.name}
						value={this.props.value}
						onChange={this.onChange.bind(this)}/>
			</div>
		);
	}

	onChange(event) {
		if (this.props.onChange) {
			this.props.onChange(event.target.value);
		}
	}

	getOptions() {
		const sortedLocations = this.props.locations.sort((a, b) => {
			return a.isDefault ? - 1 : 1;
		});

		const locations = sortedLocations.map(location => {
			return {
				value: location.name,
				label: location.name,
			};
		});

		locations.push({
			value: 'userDefined',
			label: this.props.intl.formatMessage({id: 'TripDetails.Location.Other'}),
		});

		return locations;
	}
}

LocationPicker.propTypes = {
	onChange: PropTypes.func,
	isDisabled: PropTypes.bool,
	name: PropTypes.string.isRequired,
	locations: PropTypes.arrayOf(Location).isRequired,
	value: PropTypes.string,
	intl: Intl.isRequired,
};

export default injectIntl(LocationPicker);
