import leftPad from './leftPad';

function formatDateAsYYYYMMDDHHMM(d) {
	const date = `${d.getFullYear()}-${leftPad(d.getMonth() + 1)}-${leftPad(d.getDate())}`;
	const time = `${leftPad(d.getHours())}:${leftPad(d.getMinutes())}`;

	return `${date} ${time}`;
}

export default formatDateAsYYYYMMDDHHMM;
